import React, { useState, useEffect, useRef } from "react";
import StateList from "../state-list.json";
import "./address-search.scss";
import { useNavigate } from "react-router";
import { USPSDescription } from "../usps-description/usps-description.jsx";
import { getUrlParams, getUICPRedirect, BASE_URL, validateUatEnv, validateUrl } from "../../utils/utils.js";
import useAnalyticsEventTracker from "../../utils/useAnalyticsEventTracker";
import axios from "axios";
import validator from "validator";
import {
  getAddressList,
  getOptInApplicant
} from "../../service/addressSearch.js";
import { FormattedMessage, injectIntl } from "react-intl";
import FraudPreventionModal from "../fraudPrevention-modal/FraudPreventionModal";
import Button from "../../common/Button";
import StepActiveStatus from "../../common/Stepper/StepActiveStatus";
import states from "../../i18n/states";

export function AddressSearch(props) {
    const uspsDescriptionRef = useRef(null);
    const scrollToUSPSDescription = (event) => {
      event.preventDefault();
      if (uspsDescriptionRef.current) {
        uspsDescriptionRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    };
  const gaEventTracker = useAnalyticsEventTracker("Address Search");
  const { formatMessage } = props.intl;
  const {
    setLoading,
    setEnrollmentCode,
    setAddressInput,
    setPostOffices,
    showPrefilledInfo,
    activeStep,
    setActiveStep,
    steps,
    setSteps
  } = props;
  const [showFraudPreventionModal, setShowFraudPreventionModal] = useState(false);
  const [streetAddr1, setStreetAddr1] = useState("");
  const [streetAddr2, setStreetAddr2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});

  const applyErrorBorderHeight = (elementName, errorClass) => {
    setTimeout(() => {
      let heightVal = document.getElementById(elementName).parentElement?.clientHeight - 20;
      document.getElementById(errorClass).style.height = "" + heightVal + "px";
    })
  };

  const handleChange = e => {
    e.preventDefault();
    const { id, value } = e.target;
    let errorObj = { firstName: undefined, lastName: undefined, email: undefined, streetAddr1: undefined, streetAddr2: undefined, city: undefined, zipcode: undefined, state: undefined };
    switch (id) {
      case "first-name":
        errorObj = {
          firstName: {
            mainError: (!value || !value.match("^[ A-Za-z'-]*$")) ? <FormattedMessage id="fn_mainError" /> : "",
            subError: value.match("^[ A-Za-z'-]*$") ? "" : <div><FormattedMessage id="fn_subError" values={{ br: <br /> }} /> </div>
          }
        };
        applyErrorBorderHeight("first-name", "fnameError");
        break;
      case "last-name":
        errorObj = {
          lastName: {
            mainError: (!value || !value.match("^[ A-Za-z'.-]*$")) ? <FormattedMessage id="ln_mainError" /> : "",
            subError: value.match("^[ A-Za-z'.-]*$") ? "" : <div><FormattedMessage id="ln_subError" values={{ br: <br /> }} /></div>
          }
        };
        applyErrorBorderHeight("last-name", "lnameError");
        break;
      case "email":
        errorObj = {
          email: {
            mainError: (!value || !value.match("^[a-zA-Z0-9_+&*-]+(?:\\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,7}$")) ? <FormattedMessage id="email_mainError" /> : "",
            subError: (!value || (value && value.match("^[a-zA-Z0-9_+&*-]+(?:\\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,7}$"))) ? ""
            : <div><FormattedMessage id="email_subError" values={{ br: <br />, b: data => <b>{data}</b> }} /></div>
          }
        };
        applyErrorBorderHeight("email", "emailError");
        break;
      case "street-address-1":
        errorObj = {
          streetAddr1: {
            mainError: (!value || !value.match("^[ A-Za-z0-9#'./-]*$")) ? <FormattedMessage id="addr1_mainError" /> : "",
            subError: value.match("^[ A-Za-z0-9#'./-]*$")
              ? ""
              : <div><FormattedMessage id="addr1_subError" values={{ br: <br /> }} /></div>
          }
        };
        applyErrorBorderHeight("street-address-1", "address1Error");
        break;
      case "street-address-2":
        errorObj = {
          streetAddr2: value.match("^[ A-Za-z0-9#'./-]*$")
            ? ""
            : <FormattedMessage id="addr2_mainError"  values={{ br: <br /> }} />
        };
        applyErrorBorderHeight("street-address-2", "address2Error");
        break;
      case "city":
        errorObj = {
          city: {
            mainError: (!value || !value.match("^\\S.[a-zA-Z-. ]+(?:['a-z])*$")) ? <FormattedMessage id="city_mainError" /> : "",
            subError: (!value || (value && value.match("^\\S.[a-zA-Z-. ]+(?:['a-z])*$")))
              ? ""
              : <div><FormattedMessage id="city_subError" values={{ br: <br /> }} /></div>
          }
        };
        applyErrorBorderHeight("city", "cityError");
        break;
      case "zipcode":
        errorObj = {
          zipcode: {
            mainError: (!value) ? <FormattedMessage id="zipcode_mainError" /> : "",
            subError: !value ? "" : value.match("^([0-9]{5})(?:-[0-9]{4})?$")
              ? ""
              : <div><FormattedMessage id="zipcode_subError" values={{ br: <br />, b: data => <b>{data}</b> }} /></div>
          }
        };
        applyErrorBorderHeight("zipcode", "zipcodeError");
        break;
      case "state":
        errorObj = {
          state: (value === "Select State" || value === "Seleccione su estado") ? <FormattedMessage id="state_mainError" /> : ""
        };
        applyErrorBorderHeight("state", "stateError");
        break;
      default:
        break;
    }

    setErrors(
      Object.keys(errors).length === 0 ? errorObj : { ...errors, ...errorObj }
    );
  };

  useEffect(() => {
    if (
      !streetAddr1 ||
      !state ||
      !zipcode ||
      !city ||
      !firstName ||
      !lastName ||
      !email ||
      !!errors.firstName?.mainError || !!errors.firstName?.subError ||
      !!errors.lastName?.mainError || !!errors.lastName?.subError ||
      !!errors.zipcode?.mainError || !!errors.zipcode?.subError ||
      !!errors.city?.mainError || !!errors.city?.subError ||
      !!errors.streetAddr1?.mainError || !!errors.streetAddr1?.subError ||
      !!errors.streetAddr2 ||
      !!errors.email?.mainError || !!errors.email?.subError ||
      !!errors.state
    ) {
      setIsFormInvalid(true);
    } else {
      setIsFormInvalid(false);
    }
  }, [streetAddr1, state, zipcode, city, firstName, lastName, email]);

  const params = getUrlParams();
  useEffect(() => {
      {params && params.auth_modal && (params.auth_modal === "true") ? setShowFraudPreventionModal(false) : setShowFraudPreventionModal(true)};
    const searchDetails = JSON.parse(sessionStorage.getItem("searchDetails"));
    const filteredState = StateList.filter(state => {
      if (state.abbreviation === params.swa) {
        return true
      }
      return false
    })
    if (searchDetails && showPrefilledInfo) {
      setFirstName(searchDetails.firstName || "");
      setLastName(searchDetails.lastName || "");
      setEmail(searchDetails.email || "");
      setCity(searchDetails.city || "");
      setZipcode(searchDetails.zipcode || "");
      setStreetAddr1(searchDetails.streetAddr1 || "");
      setStreetAddr2(searchDetails.streetAddr2 || "");
      if (searchDetails.state) {
        const stateCode = StateList.find(
          s => s.abbreviation === searchDetails.state
        );
        setState(stateCode.abbreviation || "");
      } else {
        setState("");
      }
    }
    if (!params.swa_xid || params.swa && !validateUrl(params.swa, params.swa_xid)) {
      navigate(params.swa ? `/error?swa=${params.swa}&lang_cd=${params.lang_cd || "en"}` : '/error', { state: { type: "WebAddressIncomplete" } });
    }
    if (!filteredState.length > 0) {
      navigate('/error', { state: { type: "WebAddressIncomplete" } });
    }
  }, []);

  const url = `${getUICPRedirect()}/start/${params.swa}/?swa=${params.swa}&swa_xid=${params.swa_xid
    }&lang_cd=${params.lang_cd || "en"}`;

  const sendEmailRequest = enrollmentCode => {
    var emailConfig = {
      method: "get",
      url: `https://${BASE_URL}/api/getEmailHtml/?enrollment=${enrollmentCode}&action=send`,
      headers: {
        'Accept': 'text/plain',
        'X-Frame-Options': 'DENY',
        "swa": params.swa,
        'Content-Security-Policy': 'frame-ancestors \'none\';'
      },
    };
    axios(emailConfig);
  };

  const onSearchButtonClick = async searchParams => {
    const searchDetails = {
      firstName,
      lastName,
      email,
      streetAddr1,
      streetAddr2,
      zipcode,
      city,
      state
    };
    
    localStorage.setItem('firstName', searchDetails.firstName);
    localStorage.setItem('lastName', searchDetails.lastName);
    localStorage.setItem(
      'streetAddress1',
      searchDetails.streetAddr1
    );
    localStorage.setItem(
      'streetAddress2',
      searchDetails.streetAddr2 || ' '
    );
    localStorage.setItem('city', searchDetails.city);
    localStorage.setItem('state', searchDetails.state);
    localStorage.setItem('zipcode', searchDetails.zipcode);
    localStorage.setItem('email', searchDetails.email);
    sessionStorage.setItem("searchDetails", JSON.stringify(searchDetails));
    const payload = {
      streetAddress: `${searchParams.streetAddr1} ${searchParams.streetAddr2}`.trim(),
      city: searchParams.city.trim(),
      state: searchParams.state,
      zipCode: searchParams.zipcode
    };

    setAddressInput(payload);
    setLoading(true);
    try {
      const response = await getAddressList(JSON.stringify(payload));
      const optInPayload = JSON.stringify({
        firstName: searchParams.firstName.trim(),
        lastName: searchParams.lastName.trim(),
        streetAddress: `${searchParams.streetAddr1} ${searchParams.streetAddr2}`.trim(),
        city: searchParams.city.trim(),
        state: searchParams.state,
        zipCode: searchParams.zipcode,
        emailAddress: searchParams.email,
        IPPAssuranceLevel: "1.5"
      });
      if (
        response &&
        response.data &&
        Array.isArray(response.data.postOffices) &&
        response.data.postOffices.length === 0
      ) {
        navigate(`/error?swa=${params.swa}&swa_xid=${params.swa_xid
          }&lang_cd=${params.lang_cd || "en"}`, { state: { type: "OutOfNetwork" } });
      } else {
        const optInResponse = await getOptInApplicant(optInPayload);
        if (response && optInResponse) {
          setPostOffices(response.data.postOffices);
          setEnrollmentCode(optInResponse.data.enrollmentCode);
          sendEmailRequest(optInResponse.data.enrollmentCode);
          if (Object.keys(params).length) {
            setSteps((prevSteps) => {
              prevSteps.forEach((step) => {
                if (step.id === activeStep.id && activeStep.id !== steps[steps.length - 1].id) {
                  prevSteps[step.id].disabled = false;
                  return
                }
              })
              return prevSteps;
            });
            setActiveStep(steps[activeStep.id]);
            navigate(
              `/enrollment?swa=${params.swa}&swa_xid=${params.swa_xid
              }&lang_cd=${params.lang_cd || "en"}&expiration_date=${params.expiration_date}`
            );
          }
        }
      }
    } catch (error) {
      navigate(`/error?swa=${params.swa}&swa_xid=${params.swa_xid
        }&lang_cd=${params.lang_cd || "en"}`, { state: { type: "HitaWall" } });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="address-search">
      <section id="main-content">
        <h2 className="heading">
          {" "}
          <StepActiveStatus activeStep={activeStep} />
          <span className="subheading-text">
            <FormattedMessage id="personal_info_heading_text" />
          </span>
        </h2>
        <div className="helper-text">
          <FormattedMessage id="heading_helper_text" />
          <a href="#uspsDescription" onClick={scrollToUSPSDescription}>
          <FormattedMessage id="heading_helper_text_link" />
          </a><br />
          <div className="helper-text-indent"><FormattedMessage id="form_required_info_text" /><div className="required-field-quote required-field">*</div></div>
        </div>
        <div className="form form-padding">
          <div className={errors && errors.firstName && (errors.firstName.subError || errors.firstName.mainError) ? "error-field-padding field" : "field"}>
            <label htmlFor={formatMessage({
              id: "input_first_name"
            })}>
              <FormattedMessage id="input_first_name" /><div className="required-field">*</div>
            </label>
            <div className={errors && errors.firstName && (errors.firstName.subError || errors.firstName.mainError) ? "" : "display-none"}>
              <div id="fnameError" className="error-left-border"></div>
              <div className="error-text error-bold-font">{errors && errors.firstName && errors.firstName.mainError}</div>
              <div className="error-text">{errors && errors.firstName && errors.firstName.subError} </div>
            </div>
            <input
              id="first-name"
              placeholder={formatMessage({
                id: "input_first_name_placeholder"
              })}
              maxLength={255}
              value={firstName}
              data-test-id="first-name"
              className={errors.firstName ? "error-field" : ""}
              onBlur={e => {
                handleChange(e);
              }
              }
              onChange={e => {
                handleChange(e);
                setFirstName(e.target.value);
              }}
            />
          </div>
          <div className={errors && errors.lastName && (errors.lastName.subError || errors.lastName.mainError) ? "error-field-padding field" : "field"}>
            <label htmlFor={formatMessage({
              id: "input_last_name"
            })}>
              <FormattedMessage id="input_last_name" /><div className="required-field">*</div>
            </label>
            <div className={errors && errors.lastName && (errors.lastName.subError || errors.lastName.mainError) ? "" : "display-none"}>
              <div id="lnameError" className="error-left-border"></div>
              <div className="error-text error-bold-font">{errors && errors.lastName && errors.lastName.mainError}</div>
              <div className="error-text">{errors && errors.lastName && errors.lastName.subError}</div>
            </div>
            <input
              id="last-name"
              placeholder={formatMessage({
                id: "input_last_name_placeholder"
              })}
              maxLength={255}
              value={lastName}
              className={errors.lastName ? "error-field" : ""}
              data-test-id="last-name"
              onBlur={e => {
                handleChange(e);
              }
              }
              onChange={e => {
                handleChange(e);
                setLastName(e.target.value);
              }}
            />
          </div>
          <div className={errors && errors.email && (errors.email.subError || errors.email.mainError) ? "error-field-padding field" : "field"}>
            <label htmlFor={formatMessage({
              id: "input_email"
            })}>
              <FormattedMessage id="input_email" /><div className="required-field">*</div>
            </label>
            <div className={errors && errors.email && (errors.email.subError || errors.email.mainError) ? "" : "display-none"}>
              <div id="emailError" className="error-left-border"></div>
              <div className="error-text error-bold-font">{errors && errors.email && errors.email.mainError}</div>
              <div className="error-text">{errors && errors.email && errors.email.subError}</div>
            </div>
            <input
              id="email"
              placeholder={formatMessage({
                id: "input_email_placeholder"
              })}
              value={email}
              type="email"
              data-test-id="email"
              className={errors.email ? "error-field" : ""}
              onBlur={e => {
                handleChange(e);
              }
              }
              onChange={e => {

                setEmail(e.target.value);
              }}
            ></input>
          </div>
          {/* <div className="field">
          <label>*Phone Number</label>
          <input
            id="phone-number"
            value={phoneNumber}
            data-test-id="phone-number"
            onBlur={e => {
              setPhoneNumber(e.target.value);
            }}
          />
        </div> */}
          <div className={errors && errors.streetAddr1 && (errors.streetAddr1.subError || errors.streetAddr1.mainError) ? "error-field-padding field" : "field"}>
            <label htmlFor={formatMessage({
              id: "input_street_address_1"
            })}>
              <FormattedMessage id="input_street_address_1" /><div className="required-field">*</div>
            </label>
            <div className={errors && errors.streetAddr1 && (errors.streetAddr1.subError || errors.streetAddr1.mainError) ? "" : "display-none"}>
              <div id="address1Error" className="error-left-border"></div>
              <div className="error-text error-bold-font">{errors && errors.streetAddr1 && errors.streetAddr1.mainError}</div>
              <div className="error-text">{errors && errors.streetAddr1 && errors.streetAddr1.subError}</div>
            </div>
            <input
              id="street-address-1"
              placeholder={formatMessage({
                id: "input_street_address_1_placeholder"
              })}
              maxLength={255}
              value={streetAddr1}
              data-test-id="street-address-1"
              className={errors.streetAddr1 ? "error-field" : ""}
              onBlur={e => {
                handleChange(e);
              }
              }
              onChange={e => {
                handleChange(e);
                setStreetAddr1(e.target.value);
              }}
            ></input>
          </div>
          <div className={errors && errors.streetAddr2 ? "error-field-padding field" : "field"}>
            <label htmlFor={formatMessage({
              id: "input_street_address_2"
            })}>
              <FormattedMessage id="input_street_address_2" />
            </label>
            <div className={errors && errors.streetAddr2 ? "" : "display-none"}>
              <div id="address2Error" className="error-left-border"></div>
              <div className="error-text">{errors && errors.streetAddr2}</div>
            </div>
            <input
              id="street-address-2"
              placeholder={formatMessage({
                id: "input_street_address_2_placeholder"
              })}
              data-test-id="street-address-2"
              value={streetAddr2}
              maxLength={255}
              disabled={
                JSON.parse(sessionStorage.getItem("searchDetails") || "{}")
                  .streetAddr2
              }
              className={errors.streetAddr2 ? "error-field" : ""}
              onBlur={e => {
                handleChange(e);
              }
              }
              onChange={e => {
                handleChange(e);
                setStreetAddr2(e.target.value);
              }}
            ></input>
          </div>
          <div className={errors && errors.city && (errors.city.subError || errors.city.mainError) ? "error-field-padding field" : "field"}>
            <label htmlFor={formatMessage({
              id: "input_city_name_1"
            })}>
              <FormattedMessage id="input_city_name_1" />
              <div className="required-field">*</div>
            </label>
            <div className={errors && errors.city && (errors.city.subError || errors.city.mainError) ? "" : "display-none"}>
              <div id="cityError" className="error-left-border"></div>
              <div className="error-text error-bold-font">{errors && errors.city && errors.city.mainError}</div>
              <div className="error-text">{errors && errors.city && errors.city.subError} </div>
            </div>
            <input
              id="city"
              placeholder={formatMessage({
                id: "input_city_name_placeholder"
              })}
              maxLength={50}
              value={city}
              data-test-id="city"
              className={errors.city ? "error-field" : ""}
              onBlur={e => {
                handleChange(e);
              }
              }
              onChange={e => {
                setCity(e.target.value);
              }}
            />
          </div>
          <div className={errors && errors.state ? "error-field-padding field" : "field"}>
            <label htmlFor={formatMessage({
              id: "input_state_name"
            })}>
              <FormattedMessage id="input_state_name" /><div className="required-field">*</div>
            </label>
            <div className={errors && errors.state ? "" : "display-none"}>
              <div id="stateError" className="error-left-border"></div>
              <div className="error-text error-bold-font">{errors && errors.state}</div>
            </div>
            <select
              id="state"
              aria-label="Select State"
              placeholder={formatMessage({
                id: "input_state_name_placeholder"
              })}
              onBlur={e => {
                handleChange(e);
              }
              }
              onChange={e => {
                handleChange(e);
                setState(e.target.value);
              }}
              value={state}
              className={
                errors.state ? "state-dropdown error-field" : "state-dropdown"
              }
              data-test-id="state"
            >
                <FormattedMessage 
                  id="Select_State_DropdownText" 
                  children={
                    (formatedMessage) => <option value="Select State">{formatedMessage}</option>
                } />
              {StateList.map(state => {
                return (
                  <option
                    id={state.abbreviation}
                    key={state.abbreviation}
                    data-test-id={state.abbreviation}
                  >
                    {state.abbreviation}
                  </option>
                );
              })}
            </select>
          </div>

          <div className={errors.zipcode && (errors.zipcode.subError || errors.zipcode.mainError) ? "error-field-padding field" : "field"}>
            <label htmlFor={formatMessage({
              id: "input_zipcode"
            })}>
              <FormattedMessage id="input_zipcode" /><div className="required-field">*</div>
            </label>
            <div className={errors.zipcode && (errors.zipcode.subError || errors.zipcode.mainError) ? "" : "display-none"}>
              <div id="zipcodeError" className="error-left-border"></div>
              <div className="error-text error-bold-font">{errors.zipcode && errors.zipcode.mainError}</div>
              <div className="error-text">{errors.zipcode && errors.zipcode.subError}</div>
            </div>
            <input
              id="zipcode"
              placeholder={formatMessage({
                id: "input_zipcode_placehoder"
              })}
              className={errors.zipcode ? "error-field" : ""}
              value={zipcode}
              data-test-id="zipcode"
              onBlur={e => {
                handleChange(e);
              }
              }
              onChange={e => {
                handleChange(e);
                setZipcode(e.target.value);
              }}
            ></input>
          </div>
          <div className="footer-actions">
            <Button type="primary"
              id="search-button"
              data-testid="search-button"
              disabled={isFormInvalid}
              onClick={() => {
                gaEventTracker("SubmitButton");
                onSearchButtonClick({
                  streetAddr1,
                  streetAddr2,
                  city,
                  state,
                  zipcode,
                  firstName,
                  lastName,
                  email
                });
              }}
            >
              <FormattedMessage id="form_submit_button" />
            </Button>
            {(states[params.swa]?.chooseDifferentVerificationButton_USPS === "true") ?
              <Button type="secondary" disabled={validateUatEnv() ? true : false}>
                {validateUatEnv() ?
                  <FormattedMessage id="different_verification_method_link" /> :
                  <a href={url} data-test-id="enrollment-diff-verification" onClick={() => gaEventTracker("Choose different button in search page")}>
                    <FormattedMessage id="different_verification_method_link" />
                  </a>}
              </Button>
              : <span>{" "}</span>
            }
          </div>
        </div>
        <div ref={uspsDescriptionRef} id="uspsDescription">
        <USPSDescription swa={params.swa} />
        </div>
        <FraudPreventionModal
          showFraudPreventionModal={showFraudPreventionModal}
          setShowFraudPreventionModal={setShowFraudPreventionModal}
        />
      </section>
    </div>
  );
}

export default injectIntl(AddressSearch);
