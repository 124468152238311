import React, { useState } from "react";
import { Modal } from "@mui/material";
import "./fraudPrevention-modal.scss";
import { FormattedMessage } from "react-intl";
import { richText } from "../../i18n/states";


function FraudPreventionModal({
    showFraudPreventionModal,
    setShowFraudPreventionModal
}) {

    const onFraudModalBtnClick = async () => {
        setShowFraudPreventionModal(false);
    };

    return (
        <Modal
            open={showFraudPreventionModal}
            className="fraud-modal"
        >
            <div className="fraud-modal-container">
                <div className="fraud-modal-header">
                    <p><FormattedMessage id="fraud_modal_header" values={{ ...richText }} /></p>
                </div>
                <div className="fraud-modal-paragraph">
                    <span>
                        <p>
                            <FormattedMessage id="fraud_modal_para1" values={{ sup: data => <sup>{data}</sup>, ...richText }} />
                            <br />
                        </p>
                    </span>
                    <span>
                        <p>
                            <FormattedMessage id="fraud_modal_para2" values={{ ...richText }} />
                            <br />
                            <br />
                        </p>
                    </span>
                </div>
                <div className="fraud-modal-btn-container">
                    <button
                        className="fraud-btn"
                        onClick={() => {
                            return onFraudModalBtnClick();
                        }}
                    >
                        <FormattedMessage id="fraud_modal_button" />
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default FraudPreventionModal;
