import "./error-page.scss";
import { getUrlParams, getUICPRedirect, validateUatEnv } from "../../utils/utils";
import { useLocation, useNavigate } from "react-router";
import { FormattedMessage } from "react-intl";
import Button from "../../common/Button";
import states, { richText } from "../../i18n/states";
import useAnalyticsEventTracker from "../../utils/useAnalyticsEventTracker";
import { ReactComponent as EditIcon } from "../../icons/edit.svg";
export const ErrorPage = () => {
  const firstName = localStorage.getItem('firstName');
  const lastName = localStorage.getItem('lastName');
  const streetAddr1 = localStorage.getItem('streetAddress1');
  const streetAddr2 = localStorage.getItem('streetAddress2');
  const city = localStorage.getItem('city');
  const zipcode = localStorage.getItem('zipcode');
  const email = localStorage.getItem('email');
  const myState = localStorage.getItem('state');
  const params = getUrlParams();
  const navigate = useNavigate();
  const url = `${getUICPRedirect()}/start/${params.swa}/?swa_xid=${params.swa_xid
    }&lang_cd=${params.lang_cd || "en"}`;
  const gaEventTracker = useAnalyticsEventTracker("Error Page");
  const ErrorDetails = {
    WebAddressIncomplete: {
      title: "Invalid URL",
      errorMessage: (
        <div className="first-error-page-wrapper" id="error_page">
          <div className="error-page-paragraph">
            <div>
              <span className="first-para">
                {" "}
                <FormattedMessage id="invalid_url_para1" />
                <br />
              </span>
              <span className="second-para">
                <div>
                  <FormattedMessage id="invalid_url_para2" values={{ ...richText }} />
                </div>
                <br />
              </span>
              {params.swa && (<span className="third-para">
                <div>
                  <FormattedMessage
                    id={`invalid_url_para3`}
                    values={{ ...richText }}
                  />
                </div>
                <br />
              </span>)}
              <span className="fourth-para">
                <h3 className="h3-more-info">
                  <FormattedMessage id="more_info_txt" values={{ ...richText }} />
                </h3>
                <ul className="list-style">
                  <FormattedMessage
                    id="moreinfo_link"
                    values={{ ...richText }}
                  />
                </ul>
              </span>
            </div>
          </div>
        </div>
      )
    },
    HitaWall: {
      title: "We seem to have a problem",
      errorMessage: (
        <div className="second-error-page-wrapper">
          <div className="error-page-paragraph">
            <div>
              <span className="first-para">
                <FormattedMessage id="hitawall_para1" values={{ sup: data => <sup>{data}</sup> }} />
                <br />
              </span>
              <span className="second-para">
                <FormattedMessage id="hitawall_para2" />
                <br />
              </span>
              <span className="third-para">
                <FormattedMessage id="hitawall_para3" />
              </span>
              <span className="fourth-para">
                <div>
                  <FormattedMessage
                    id={`hitawall_para4`}
                    values={{ ...richText }}
                  />
                </div>
                <br />
              </span>

            </div>
          </div>
          {(states[params.swa]?.showErrorPageButton_HitaWall === "true") ?
            <div className="second-error-page-button">
              <button className="primary basic autowidth" disabled={validateUatEnv() ? true : false} >
                {validateUatEnv() ?
                  <FormattedMessage id="enrollment_different_verification_method_link" /> :
                  <a href={url} className="link-decoration" data-test-id="enrollment-diff-verification" onClick={() => gaEventTracker("Choose different button in error page")}>
                    <FormattedMessage id="enrollment_different_verification_method_link" />
                  </a>}
              </button>
            </div>
            : <span>{" "}</span>
          }
          <span className="fifth-para">
            <h3 className="h3-more-info">
              <FormattedMessage id="more_info_txt" values={{ ...richText }} />
            </h3>
            <ul className="list-style">
              <FormattedMessage
                id="moreinfo_link"
                values={{ ...richText }}
              />
            </ul>
          </span>
        </div>
      )
    },
    OutOfNetwork: {
      title: "No Participating Post Offices Found",
      errorMessage: (
        <div className="third-error-page-wrapper">
          <div className="error-page-paragraph">
            <div>
              <span className="first-para">
                {" "}
                <div>
                  <FormattedMessage id="out_of_network_para1" values={{ sup: data => <sup>{data}</sup> }} />
                </div>
              </span>
              <span className="second-para">
                <FormattedMessage id="out_of_network_para2" />
              </span>
              <hr className="usps-mid-hr" />
              <div className="user-details-wrapper">
                <div className="id-wrapper">
                  <div className="field-wrapper">
                    <div className="id-details">
                      <FormattedMessage id="enrollment_personal_information_text" />
                    </div>
                    <div
                      id="edit-address"
                      className="edit-wrapper"
                      data-test-id="edit-address"
                      onClick={() => {
                        gaEventTracker("Edit Identification Details");
                        if (Object.keys(params).length) {
                          navigate(
                            `/editsearch?swa=${params.swa}&swa_xid=${params.swa_xid
                            }&lang_cd=${params.lang_cd || "en"}&expiration_date=${params.expiration_date}`
                          );
                        } else {
                          navigate("/editsearch");
                        }
                      }}
                    >
                      <EditIcon aria-label="Edit Address " />
                      <button className="edit-id-details">
                        <FormattedMessage id="enrollment_id_details_editicon_text" />
                      </button>
                    </div>
                  </div>
                  <div className="field-wrapper">
                    <FormattedMessage
                      id="enrollment_id_details_name_bold_text"
                      values={{ ...richText }}
                    />
                    <span className="field-wrapper-input">{`${firstName} ${lastName}`}</span>
                  </div>
                  <div className="field-wrapper">
                    <FormattedMessage
                      id="enrollment_id_details_email_bold_text"
                      values={{ ...richText }}
                    />
                    <span className="field-wrapper-input">{email}</span>
                  </div>
                  <div className="legal-address-wrapper">
                    <span className="address-wrapper">
                      <FormattedMessage
                        id="enrollment_id_details_address_bold_text"
                        values={{ ...richText }}
                      />
                    </span>
                    <span className="address-field-wrapper-input">
                      {`${streetAddr1} ${streetAddr2}, ${city}, ${myState} ${zipcode}`}
                    </span>
                  </div>
                </div>
              </div>
              <hr className="usps-mid-hr" />
              {(states[params.swa]?.showErrorPageButton_OutOfNetwork === "true") ?
                <div className="third-error-page-button">
                  <FormattedMessage id="out_of_network_para3" />
                  <br /><br /><br />
                  <button className="primary basic autowidth" disabled={validateUatEnv() ? true : false} >
                    {validateUatEnv() ?
                      <FormattedMessage id="enrollment_different_verification_method_link" /> :
                      <a href={url} className="link-decoration" data-test-id="enrollment-diff-verification" onClick={() => gaEventTracker("Choose different button in error page")}>
                        <FormattedMessage id="enrollment_different_verification_method_link" />
                      </a>}
                  </button>
                </div>
                : <span>{" "}</span>
              }
              <span className="third-para">
                <div>
                  <FormattedMessage
                    id={`out_of_network_para4`}
                    values={{ ...richText }}
                  />
                </div>
                <br />
              </span>
              <span className="fourth-para">
                <h3 className="h3-more-info">
                  <FormattedMessage id="more_info_txt" values={{ ...richText }} />
                </h3>
                <ul className="list-style">
                  <FormattedMessage
                    id="moreinfo_link"
                    values={{ ...richText }}
                  />
                </ul>
              </span>
            </div>
          </div>
        </div>
      )
    }
  };

  const { state } = useLocation();
  return (
    <div className="main-container">
      <div className="error-page-paragraph">
        {state?.type ? ErrorDetails[state.type].errorMessage : ErrorDetails["WebAddressIncomplete"].errorMessage}
      </div>
    </div>
  );
};
