import { useState, useEffect } from "react";
import "./App.scss";
import StateList from "./components/state-list.json";
import Enrollment from "./components/enrollment/Enrollment";
import EnrollmentEmail from "./components/enrolllment-email/EnrollmentEmail";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Reports from "./components/reports/Reports";
import AddressSearch from "./components/address-search/AddressSearch";
import SignIn from "./components/sign-in/SignIn";
import ForgotPassword from "./components/forgot-password/ForgotPassword";
import ResetPassword from "./components/reset-password/ResetPassword";
import { getCSRFToken } from "./service/addressSearch";
import Header from "./pages/header";
import Footer from "./pages/footer";
import { ErrorPage } from "./components/error-page/ErrorPage";
import { IntlProvider, FormattedMessage } from "react-intl";
import { LOCALE } from "./i18n/locales";
import { messages } from "./i18n/messages";
import states from './i18n/states.js';
import { getUrlParams } from "./utils/utils";
import { STEPS } from "./constants";
import Stepper from "./common/Stepper/Stepper";
import { useNavigate } from "react-router";
import axios from "axios";
import { spacing } from "@mui/system";

function App() {
  const [postOffices, setPostOffices] = useState([]);
  const [addressInput, setAddressInput] = useState({});
  const [enrollmentCode, setEnrollmentCode] = useState();
  const [showStepper, setShowStepper] = useState(false);
  const [barcodeImageUrl, setBarcodeImageUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [locale, setLocale] = useState("en");
  const params = getUrlParams();
  const navigate = useNavigate();

  axios.interceptors.response.use(
    res => res,
    err => {
      let axiosError = "AxiosError"
      if(err.name.toUpperCase() === axiosError.toUpperCase()){
        navigate(`/error?swa=${params.swa}&swa_xid=${params.swa_xid
        }&lang_cd=${params.lang_cd || "en"}`, { state: { type: "HitaWall" } });
        return
      }
      return err
    }
  )

  const [headerText, setHeaderText] = useState(
    <h1>
      <FormattedMessage id="header_text" values={{ sup: data => <sup>{data}</sup> }}/>{" "}
      <sup>
        <FormattedMessage id="header_text_sup" />
      </sup>
    </h1>
  );
  const [headerDividerLine, setHeaderDividerLine] = useState(
    <div className="header-style"/>
  );

  const [activeStep, setActiveStep] = useState(STEPS[0]);
  const [steps, setSteps] = useState(STEPS);

  const updateHeadingText = text => {
    setHeaderText(text);
  };
  let location = useLocation();
  useEffect(() => {
    const path = location.pathname;
    setHeaderDividerLine(
      <div className="header-style"/>
    );
    if(path !== "/error"){
      getCSRFToken();
    }
    if (params.swa) {
      const stateObj = StateList.find(
        s => s.abbreviation === params.swa
      );
      if (!stateObj) {
        navigate('/error', { state: { type: "WebAddressIncomplete" } });
      }
    }
    if(path === '/search' || path === '/editsearch' || path === '/enrollment'){
      setShowStepper(true);
    }else{
      setShowStepper(false);
    }
    // This will capture every page changes
    if (params["lang_cd"]) {
      setLocale(params["lang_cd"] === "es" ? LOCALE.SPANISH : LOCALE.ENGLISH);
      document.documentElement.setAttribute("lang",params["lang_cd"] === "es" ? LOCALE.SPANISH : LOCALE.ENGLISH);
    }
  
    if (path === "/error") {
      const type = location?.state?.type ? location.state.type : "";
      setHeaderDividerLine("");
      if (type === "HitaWall") 
        setHeaderText(
          <div class="main-container">
            <div className="second-error-page-wrapper">
              <div className="error-page-heading">
                <div>
                  <FormattedMessage id="hitawall_heading" values={{ sup: data => <sup>{data}</sup> }}/>
                </div>
              </div>
            </div>
          </div>
        );
      else if (type === "OutOfNetwork") 
        setHeaderText(
          <div class="main-container">
            <div className="third-error-page-wrapper">
              <div className="error-page-heading">
                <div>
                <FormattedMessage id="out_of_network_heading" values={{ sup: data => <sup>{data}</sup> }}/>
                </div>
              </div>
            </div>
          </div>
        );
      else
        setHeaderText(
          <div class="main-container">
            <div className="first-error-page-wrapper">
              <div className="error-page-heading">
                <FormattedMessage id="invalid_url_heading" />
              </div>
            </div>
          </div>
        );

    } else if (path === "/search" || path === "/editsearch") {
      window.sendToGA("search pageview", path);
      setHeaderText(
        <h1>
          <FormattedMessage id="header_text" values={{ sup: data => <sup>{data}</sup> }}/>{" "}
          <sup>
            <FormattedMessage id="header_text_sup" />
          </sup>
        </h1>
      );
    } else if (path === "/enrollment") {
      window.sendToGA("enrollment pageview", path);
      setHeaderText(
        <h1>
          <FormattedMessage id="enrollment_component_heading" />
        </h1>
      );
    } else if (path === "/reports") {
      setHeaderText(
        <h1>
          <FormattedMessage id="reports_component_heading" />
        </h1>
      );
    }
  }, [location]);
  const stateMessages = states[params.swa] || {};
  const localWiseStateMessages = stateMessages[params.lang_cd] || {};
  return (
    <IntlProvider
    // Translation of the state messages will be done as part of future story(story number not available at the moment)
      messages={{...messages[locale] , ...stateMessages, ...localWiseStateMessages} }
      locale={locale}
      defaultLocale={LOCALE.ENGLISH}
    >
      <div className="App">
        <Header />
        {loading && (
          <div className="loading-wrapper">
            <div className="loader"></div>
          </div>
        )}
        {headerDividerLine}
        <div className="header">
          {location.pathname==="/error"? <div className="header-title app-divider-line app-mb-16">{headerText}</div>: <div className="header-title">{headerText}</div>}          
        </div>  
        {showStepper && <Stepper steps={steps} setSteps={setSteps} activeStep={activeStep}  setActiveStep={setActiveStep} />}
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route
            path="/search"
            element={
              <div className="main-container">
                <AddressSearch
                  setAddressInput={setAddressInput}
                  setEnrollmentCode={setEnrollmentCode}
                  setLoading={setLoading}
                  setPostOffices={setPostOffices}
                  activeStep={activeStep}  
                  setActiveStep={setActiveStep}
                  steps={steps}
                  setSteps={setSteps}
                />
              </div>
            }
          />
          <Route
            path="/editsearch"
            element={
              <div className="main-container">
                <AddressSearch
                  setAddressInput={setAddressInput}
                  setEnrollmentCode={setEnrollmentCode}
                  setLoading={setLoading}
                  setPostOffices={setPostOffices}
                  showPrefilledInfo
                  activeStep={activeStep}
                  steps={steps}
                  setSteps={setSteps}
                  setActiveStep={setActiveStep}
                />
              </div>
            }
          />
          <Route
            path="/enrollment"
            element={
              <div className="main-container">
                <Enrollment
                  enrollmentCode={enrollmentCode}
                  streetAddress={addressInput.streetAddress}
                  city={addressInput.city}
                  state={addressInput.state}
                  zipCode={addressInput.zipCode}
                  setBarcodeImageUrl={setBarcodeImageUrl}
                  postOffices={postOffices}
                  setPostOffices={setPostOffices}
                  setLoading={setLoading}
                  barcodeImageUrl={barcodeImageUrl}
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  steps={steps}
                />
              </div>
            }
          />
          <Route path="/error" element={<ErrorPage />} />

          <Route
            path="/enrollment-email"
            element={
              <div className="main-container">
                <EnrollmentEmail
                  enrollmentCode={enrollmentCode}
                  streetAddress={addressInput.streetAddress}
                  city={addressInput.city}
                  state={addressInput.state}
                  zipCode={addressInput.zipCode}
                  barcodeImageUrl={barcodeImageUrl}
                  postOffices={postOffices}
                />
              </div>
            }
          />
          <Route
            path="/reports"
            element={
              <div className="main-container">
                <Reports updateHeadingText={updateHeadingText} setLoading={setLoading} loading={loading} />
              </div>
            }
          />
        </Routes>
        <Footer />
      </div>
    </IntlProvider>
  );
}

export default App;
