import React, { useState, useEffect } from "react";
import "./enrollment.scss";
import { getBarcodeImage } from "../../service/addressSearch";
import { useNavigate } from "react-router";
import { ReactComponent as EditIcon } from "../../icons/edit.svg";
import AddressList from "../address-list/AddressList";
import AddressModal from "../address-modal/AddressModal";
import { Modal } from "@mui/material";
import moment from "moment";
import { USPSDescription } from "../usps-description/usps-description";
import { getUrlParams, getUICPRedirect, validateUatEnv, validateUrl } from "../../utils/utils";
import useAnalyticsEventTracker from "../../utils/useAnalyticsEventTracker";
import { FormattedMessage } from "react-intl";
import { formatMessage } from "@formatjs/intl";
import Button from "../../common/Button";
import StepActiveStatus from "../../common/Stepper/StepActiveStatus";
import 'moment/locale/es'
import states, { richText } from "../../i18n/states";


function Enrollment(props) {
  const gaEventTracker = useAnalyticsEventTracker("Enrollment");
  const {
    enrollmentCode,
    streetAddress,
    zipCode,
    postOffices,
    setPostOffices,
    setLoading,
    activeStep,
    steps,
    setActiveStep,
  } = props;

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [streetAddr1, setStreetAddr1] = useState("");
  const [streetAddr2, setStreetAddr2] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [barcodeImage, setBarcodeImageUrl] = useState("");
  const generatedBarcode = enrollmentCode || sessionStorage.getItem('barcode');


  const navigate = useNavigate();

  const nearbyPostOffices =
  postOffices.length !== 0
    ? postOffices
    : JSON.parse(sessionStorage.getItem('postOffices'));

  useEffect(() => {
    const searchDetails = JSON.parse(sessionStorage.getItem("searchDetails"));

    if (searchDetails) {
      setFirstName(searchDetails.firstName || "");
      setLastName(searchDetails.lastName || "");
      setEmail(searchDetails.email || "");
      setStreetAddr1(searchDetails.streetAddr1 || "");
      setStreetAddr2(searchDetails.streetAddr2 || "");
      setZipcode(searchDetails.zipcode || "");
      setCity(searchDetails.city || "");
      setState(searchDetails.state || "");

    }

    if (!params.swa_xid || !validateUrl(params.swa, params.swa_xid)) {
      navigate(params.swa ? `/error?swa=${params.swa}` : '/error', { state: { type: "WebAddressIncomplete" } });
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem('postOffices', JSON.stringify(nearbyPostOffices));

    const getBarcodeImageUrl = async () => {
      try {
        const result = await getBarcodeImage(generatedBarcode);
        if (result) {
          sessionStorage.setItem('barcode', generatedBarcode);
          setBarcodeImageUrl(result.data);
        }
      } catch {
        console.log("Failed");
      }
    };
    getBarcodeImageUrl();
  }, [nearbyPostOffices]);

  const params = getUrlParams();
  moment.locale(params.lang_cd || 'en');

  const url = `${getUICPRedirect()}/start/${params.swa}/?swa=${params.swa}&swa_xid=${params.swa_xid
    }&lang_cd=${params.lang_cd || "en"}`;

  const finalDate = states[params.swa]?.showExpiratationDate === "true" && params.expiration_date
    ? params.expiration_date : moment().add(states[params.swa]?.enrollment_calculation_date, "days").format("MMMM DD, YYYY");

  const formattedDate = moment(finalDate).format("MMMM DD, YYYY");
  return (
    <div className="enrollment">
      <section id="main-content">
        <div className="enrollment-heading">
          <StepActiveStatus activeStep={activeStep} />
          <span className="subheading-text">
            <FormattedMessage id="enrollment_heading_text" values={{ sup: data => <sup>{data}</sup> }} />{" "}
            {formattedDate}
          </span>
        </div>
        <div className="enrollment-description">
          <p>
            <FormattedMessage
              id="enrollment_description_para1"
              values={{ sup: data => <sup>{data}</sup>, ...richText }}
            />
          </p>
        </div>
        <div className="barcode-container">
          <img src={barcodeImage} alt="barcode" />
        </div>
        <div className="enrollment-code">
          <FormattedMessage
            id="enrollment_code_bold_text"
            values={{ ...richText }}
          />
          <label className="code-number">{generatedBarcode}</label>
        </div>
        <div className="enrollment-description">
          <p>
            <span className="bold-text">
              <FormattedMessage id={`enrollment_description_para2_boldText`} />
              {states[params.swa]?.showExpiratationDate === "true" && (
                <>
                  {formattedDate}{"."}
                </>
              )}
            </span>{" "}
            <FormattedMessage id={`enrollment_description_para2`} />
          </p>
        </div>
        <div className="enrollment-btn-container">
          <Button
            type="primary"
            id="print-this-page-button"
            onClick={() => {
              window.print();
              gaEventTracker("Print this page");
            }}
            data-test-id="print-this-page-button"
          >
            <FormattedMessage id="print_this_pageBtn_text" />
          </Button>
          {/* <button
          className="btn"
          id="email-me-this-page-button"
          data-test-id="email-me-this-page-button"
          onClick={() => {
            gaEventTracker('Email me button');
            return onEmailThisPageButtonClick();
          }}
        >
          Email me this page
        </button> */}
          {/* <Button type="secondary" onClick={() => {
              gaEventTracker("Choose different method button in enrollment page");
              navigate(url);
            }}>
              <FormattedMessage id="enrollment_different_verification_method_link" />
            </Button> */}

          {(states[params.swa]?.chooseDifferentVerificationButton_USPS === "true") ?
            <Button type="secondary" disabled={validateUatEnv() ? true : false}>
              {validateUatEnv() ?
                <FormattedMessage id="different_verification_method_link" /> :
                <a href={url} onClick={() => gaEventTracker("Choose different method button in enrollment page")}>
                  <FormattedMessage id="enrollment_different_verification_method_link" />
                </a>}
            </Button>
            : <span>{" "}</span>
          }
        </div>
        <hr />
        <div className="user-details-wrapper">
          <div className="id-wrapper">
            <div className="field-wrapper">
              <div className="id-details">
                <FormattedMessage id="enrollment_personal_information_text" />
              </div>
              <div
                id="edit-address"
                className="edit-wrapper"
                data-test-id="edit-address"
                onClick={() => {
                  gaEventTracker("Edit Identification Details");
                  setActiveStep(steps[0]);
                  if (Object.keys(params).length) {
                    navigate(
                      `/editsearch?swa=${params.swa}&swa_xid=${params.swa_xid
                      }&lang_cd=${params.lang_cd || "en"}&expiration_date=${params.expiration_date}`
                    );
                  } else {
                    navigate("/editsearch");
                  }
                }}
              >
                <EditIcon aria-label="Edit Address " />
                <button className="edit-id-details">
                  <FormattedMessage id="enrollment_id_details_editicon_text" />
                </button>
              </div></div>
            <div className="field-wrapper">
              <FormattedMessage
                id="enrollment_id_details_name_bold_text"
                values={{ ...richText }}
              />
              <span className="field-wrapper-input">{`${firstName} ${lastName}`}</span>
            </div>
            <div className="field-wrapper">
              <FormattedMessage
                id="enrollment_id_details_email_bold_text"
                values={{ ...richText }}
              />
              <span className="field-wrapper-input">{email}</span>
            </div>
            <div className="legal-address-wrapper">
              <span className="address-wrapper">
                <FormattedMessage
                  id="enrollment_id_details_address_bold_text"
                  values={{ ...richText }}
                />
              </span>
              <span className="address-field-wrapper-input">
                {" "}
                {`${streetAddr1} ${streetAddr2}, ${city}, ${state} ${zipcode}`}
              </span>
            </div>
          </div>

        </div>
        <hr />
        <div className="address-top-section">
          <div>
            <span className="uspc-heading">
              <FormattedMessage id="USPS_facilities_located_around" values={{ sup: data => <sup>{data}</sup> }} />
            </span>
            <span className="uspc-adress">
            {`${streetAddr1} ${streetAddr2}, ${city}, ${state} ${zipcode}`}
            </span>
          </div>
        </div>
        <AddressList postOffices={nearbyPostOffices} heading={"USPS Location"} />
        <USPSDescription swa={params.swa} />
        {/* <Modal
        open={showBarcodeModal}
        id="barcode-modal"
        className="barcode-modal"
        data-test-id="barcode-modal"
      >
        <div
          id="barcode-modal-content"
          className="barcode-modal-content"
          data-test-id="barcode-modal-content"
        >
          <img src={barcodeImageUrl} />
          <div
            id="barcode-modal-close-btn"
            className="close-btn-container"
            data-test-id="barcode-modal-close-btn"
          >
            <button
              onClick={() => {
                gaEventTracker("Close Bar Code Modal");
                setShowBarcodeModal(false);
              }}
            >
              x
            </button>
          </div>
        </div>
      </Modal> */}
      </section>
    </div>
  );
}

export default Enrollment;
